<template>
	<div>
		<!-- <ItemEditNavbar
			@exitPage="exitPage"
			@saveItem="handleSaveItem"
			:saving="itemSaving"
		/> -->
		<VueElementLoading
			:active="itemLoading || itemSaving"
			spinner="ring"
			:text="itemsName.one + (itemLoading ? ' загрузка...' : ' сохранение...')"
			:background-color="'rgba(255, 255, 255, .7)'"
		/>

		<div class="view-wrapper item-page-wrapper">
			<div class="page-top-bg-addition" />

			<div class="mcontainer">
				<h1 class="title page-title">{{ pageTitle }}</h1>
				<div class="view-content-card card">
					<div v-if="loadContent" class="form-wrapper card-content">
						<ItemForm
							ref="ItemFormComponent"
							:item-data="itemData"
							:items-name="itemsName"
							@submit="handleSubmitForm"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { navigation, itemPageMixin, initPageDataMixin } from '@/mixins';

export default {
	name: 'DeliveryRegionPage',

	components: {
		ItemForm: () => import('./ItemForm.vue')
	},
	mixins: [navigation, itemPageMixin, initPageDataMixin],

	computed: {
		...mapState({
			itemLoading: state => state.delivery_regions.isLoading,
			itemSaving: state => state.delivery_regions.isSaving,

			itemData: state => state.delivery_regions.itemData
		}),

		itemsName() {
			return {
				one: 'Регион',
				mult: 'Регионы'
			};
		}
	},

	methods: {
		...mapActions({
			// show_overlay: 'show_overlay',
			setup_navbar: 'setup_navbar',

			fetch_item: 'delivery_regions/fetch_delivery_region',
			set_item: 'delivery_regions/set_delivery_region',
			save_item: 'delivery_regions/save_delivery_region'
		})
	}
};
</script>
